var Common = {
	centerHorizontally: function (selector) {
		$(selector).each(function () {
			$(this).css({
		        'top' : '50%',
		        'margin-top' : -$(this).outerHeight()/2
		    });
		});
	}
}

$(function () {
	$('body').css('visibility', 'visible');

	$('.mc-form').ajaxChimp();

});

jQuery(document).ready(function($){
	$('.menu .idea a').click(function(){
		console.log($(this));
		$('.yt_popup').fadeIn();
		return false;
	});
	$('.yt_popup .close').click(function(){
		$('.yt_popup').fadeOut();
		return false;
	});
});