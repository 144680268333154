$(function() {
	var counter = 0;

	var timeline = new TimelineMax({paused: true});
	var t, s, animating = 0;
	var forceJoin = false;
	
	// Hide the cards
	TweenLite.set(".big-devices .cards", {autoAlpha: 0});
	TweenLite.set(".text", {autoAlpha: 0});
	TweenLite.set("#plus-minus,#check", {autoAlpha: 0});
	TweenLite.set("#plus-minus,#check", {autoAlpha: 0});
	TweenLite.set("#plus,#minus", {autoAlpha: 1});

	// Whole animation with labels
	var e = Power1.easeInOut;
	var flippingCard = $('.card-1 .card').flip({ trigger: 'manual' });

	timeline
		.to('.big-devices .cards', t = 1, {autoAlpha: 1}, 1)

		.to('.card-1', t = 0.5, {x: '0',  y: '0',  ease: e, force3D: true}, 'cardsInitialSpread')
		.to('.card-2', t, {x: '5px',  y: '5px',  ease: e, force3D: true}, 'cardsInitialSpread')
		.to('.card-3', t, {x: '10px', y: '10px', ease: e, force3D: true}, 'cardsInitialSpread')
		.to('.card-4', t, {x: '15px', y: '15px', ease: e, force3D: true}, 'cardsInitialSpread')

		.to('.card-1', t = 0.5, {x: '-130px', scale: s = 0.9, ease: e}, 'cardsSpreadIntoTwo')
		.to('.card-2', t, {x: '-125px', scale: s, ease: e}, 'cardsSpreadIntoTwo')
		.to('.card-3', t, {x: '130px', y: 0, scale: s, ease: e}, 'cardsSpreadIntoTwo')
		.to('.card-4', t, {x: '135px', y: 5, scale: s, ease: e}, 'cardsSpreadIntoTwo')
		.to('#plus-minus', 0, {x: 252, y: -240, ease: e}, 'cardsSpreadIntoTwo')
		.to('#plus-minus', 0.8, {autoAlpha: 1, ease: e}, 'cardsSpreadIntoTwo')

		.to('#plus', t = 0.7, { x: 105, y: 107, scale: s = 0.9, ease: Power1.easeOut, autoAlpha: 1}, 'spreadPoints')
		.to('#minus', t, { x: -110, y: 364, scale: s, ease: Power1.easeOut, autoAlpha: 1}, 'spreadPoints')
		.to('#plus,#minus', 0.2, {autoAlpha: 0})
		.to('#check', 0.2, {autoAlpha: 1})

		.to('#plus-minus', 0.2, {autoAlpha: 0}, 'cardsSpreadIntoFour')
		.to('.card-1', t = 0.5, {x: '-95px', y: '-130px', scale: s = 0.7, ease: e}, 'cardsSpreadIntoFour')
		.to('.card-2', t, {x: '-95px', y: '130px', scale: s, ease: e}, 'cardsSpreadIntoFour')
		.to('.card-3', t, {x: '95px', y: '-130px', scale: s, ease: e}, 'cardsSpreadIntoFour')
		.to('.card-4', t, {x: '95px', y: '130', scale: s, ease: e}, 'cardsSpreadIntoFour')

		.to('.card-1', t = 0.5, {x: 0, y: 0, scale: s = 1, ease: e}, 'zoomCard')
		.to('.card-2', t, {autoAlpha: 0}, 'zoomCard')
		.to('.card-3', t, {autoAlpha: 0}, 'zoomCard')
		.to('.card-4', t, {autoAlpha: 0}, 'zoomCard')

		.addLabel('end');

	timeline.play('zoomCard');

	function setCounters(cardNum, positive, negative) {
		var card = $('.card-' + cardNum);

		card.find('.counter-positive span').text(positive);
		card.find('.counter-negative span').text(negative);
		card.find('.arrow').hide();
		if(positive > negative) {
			card.addClass('positive').removeClass('negative');
			card.find('.counter-positive .arrow').show(100);
		} else if (positive < negative) {
			card.removeClass('positive').addClass('negative');
			card.find('.counter-negative .arrow').show(500);
		} else {
			card.removeClass('positive').removeClass('negative');
		}
	}

	function speedUp(card, current, max, positiveMultiply, negativeMultiply) {
		if(current > max) { return; }
		setCounters(card, Math.round(current * positiveMultiply), Math.round(current * negativeMultiply));

		setTimeout(function() { speedUp(card, current+1, max, positiveMultiply, negativeMultiply) }, 4);
	}

	function animateFor(time) {
		animating++;
		setTimeout(function () { animating--; }, time);
	}

	function setPill(step) {
		$('#pill svg').attr('class', function(index, classNames) {
			return classNames.replace('green', '');
		});
		$('#pill .step-' + step).attr('class', function(index, classNames) {
			return classNames + ' green';
		});
	}

	function loadFullPage() {
		$('#fullpage').fullpage({
			// paddingTop: '3em',
			anchors:['', 'cards', 'join'],
			afterLoad: function(anchorLink, index){
				var loadedSection = $(this),
					logo = $('#logo'),
					menu = $('.menu'),
					join = $('#join-button');
				$.fn.fullpage.setFitToSection(true);
				//using index
				if(index > 1){
					// console.log("more");
					if ( !logo.hasClass('smaller') ) {
						logo.addClass('smaller');
					}
					if ( !menu.hasClass('smaller') ) {
						menu.addClass('smaller');
					}
					if ( !join.hasClass('smaller') ) {
						join.addClass('smaller');
					}
				} else {
					logo.removeClass('smaller');
					menu.removeClass('smaller');
					join.removeClass('smaller');
				}
				// if(index == 3){
					// alert("Section 3 ended loading");
				// }
				//using anchorLink
				// if(anchorLink == 'secondSlide'){
					// alert("Section 2 ended loading");
				// }
			},
			afterResize: function(){
				var pluginContainer = $(this);
				$.fn.fullpage.setFitToSection(true);
				console.log('resize end');
			},
			onLeave: function (index, nextIndex, direction) {

				if(nextIndex == 2) {
					Common.centerHorizontally('.big-devices .cards, .text');
					TweenLite.set(".text", {autoAlpha: 0});

					if(direction == 'down') {
						TweenLite.to('.text-0', 1, { autoAlpha: 1 });
						flippingCard.flip(false);
						timeline.tweenFromTo('cardsFadeIn', 'cardsSpreadIntoTwo');
						animateFor(1500);
						counter = 0;
					
						for(var i = 1; i <=4; i++) {
							setCounters(i, 0, 0);
						}
						setPill(2);
					} else {
						TweenLite.to('.text-2', 1, { autoAlpha: 1 });
						flippingCard.flip(true);
						timeline.tweenFromTo('end', 'end');
						animateFor(500);

						setCounters(1, 2, 1);
						setCounters(2, 1, 2);
						setCounters(3, 2, 1);
						setCounters(4, 1, 2);
						setPill(6);
						counter = 5;
					}
				}

				if(nextIndex !== 3) {
					TweenLite.to("#join-button", 0.5, {autoAlpha: 1});
				}

				if(index == 2 && nextIndex == 3 && direction == 'down') {

					if(forceJoin) {
						forceJoin = false;
						return true;
					}

					if(animating) {
						return false;
					}

					counter++;

					if(counter > 5 && !animating) {
						TweenLite.to("#join-button", 0.5, {autoAlpha: 0});
						setPill(7);
						return true;
					}
					
					switch(counter) {
						case 1:
							animateFor(500);
							Common.centerHorizontally('#plus-minus');

							timeline.tweenFromTo('cardsSpreadIntoTwo', 'spreadPoints');
							TweenLite.to('.text-0', 0.2, { autoAlpha: 0 });
							TweenLite.to('.text-1', 1, { autoAlpha: 1 });
							setPill(3);
							break;
						case 2:
							animateFor(700 + 500);
							timeline.tweenFromTo('spreadPoints', 'cardsSpreadIntoFour');

							setTimeout(function () {
								setCounters(1, 0, 1);
								setCounters(3, 1, 0);
								$('#plus-minus .counter').html('0');
							}, 700);
							setPill(4);
							
							break;
						case 3:
							animateFor(500);
							timeline.tweenFromTo('cardsSpreadIntoFour', 'zoomCard');
							TweenLite.to('.text-1', 0.2, { autoAlpha: 0 });
							TweenLite.to('.text-2', 1, { autoAlpha: 1 });
							setCounters(2, 1, 0);
							setCounters(4, 0, 1);
							
							setPill(5);
							break;
						case 4:
							animateFor(500);
							setCounters(1, 2, 1);
							setCounters(2, 2, 1);
							setCounters(3, 1, 2);
							setCounters(4, 1, 2);

							break;
						case 5:
							animateFor(500);
							timeline.tweenFromTo('zoomCard', 'end');
							flippingCard.flip(true);
							setPill(6);
							break;
					}

					return false;
				}

				if(nextIndex == 3) {
					TweenLite.to("#join-button", 0.5, {autoAlpha: 0});
					setPill(7);
				}

				if(nextIndex == 1 && direction == 'up') {

					if(animating) {
						return false;
					}

					counter--;

					if(counter < 0 && !animating) {
						setPill(1);
						return true;
					}
					
					switch(counter) {
						case 4:
							timeline.tweenFromTo('end', 'zoomCard');
							flippingCard.flip(false);

							animateFor(500);
							setPill(5);
							break;
						case 3:
							animateFor(500);
							setCounters(1, 0, 1);
							setCounters(2, 0, 1);
							setCounters(3, 1, 0);
							setCounters(4, 1, 0);
							break;
						case 2:
							timeline.tweenFromTo('zoomCard', 'cardsSpreadIntoFour');
							TweenLite.to('.text-2', 0.2, { autoAlpha: 0 });
							TweenLite.to('.text-1', 1, { autoAlpha: 1 });
							animateFor(500);
							setPill(4);
							break;
						case 1:
							animateFor(700 + 500);
							for(var i = 1; i <=4; i++) {
								setCounters(i, 0, 0);
							}
							setTimeout(function () {
								$('#plus-minus .counter').html('1');
							}, 700 + 500);
							
							timeline.tweenFromTo('cardsSpreadIntoFour', 'spreadPoints');
							setPill(3);
							break;
						case 0:
							TweenLite.to('.text-0', 1, { autoAlpha: 1 });
							TweenLite.to('.text-1', 0.2, { autoAlpha: 0 });
							timeline.tweenFromTo('spreadPoints', 'cardsSpreadIntoTwo')
							animateFor(500);
							setPill(2);
							break;
					}
					return false;
				}
			}
		});
	}
	var fullPageLoaded = false;
	var resize = function () {
		width = Math.min($(window).width(), 1400);
		if(width <= 1280) {
			if($.fn.fullpage && fullPageLoaded) {
				$.fn.fullpage.destroy('all');
				fullPageLoaded = false;
			}
		} else {
			if(!fullPageLoaded) {
				loadFullPage();
				fullPageLoaded = true;
			}
		}
	}

	$(window).resize(resize);
	resize();

	$('#join-button').click(function () {
		TweenLite.to("#join-button", 0.5, {autoAlpha: 0});
		forceJoin = true;
	});

	Common.centerHorizontally('#pill');
	$('#pill').show();

	// $('.card-container').hover(
	// 	function () { // mouseover
	// 		if(!$(this).hasClass('rotatable')) { return; }
			
	// 		var card = $(this).children('.card');
	// 		TweenLite.to(card, 1, { rotationY: 180, });
	// 		TweenLite.to($(this), 1, { x: -25, y: -20, scale: 1.46 });
	// 	},
	// 	function () { // mouseout
	// 		if(!$(this).hasClass('rotatable')) { return; }

	// 		var card = $(this).children('.card');
	// 		TweenLite.to(card, 1, { rotationY: 0});
	// 		TweenLite.to($(this), 1, { scale: 0.7, x: -95, y: -130 });
	// 	}
	// );
	var quotes = [
		{ a: 'Publilius Syrus', q: 'A good reputation is more valuable than money.' },
		{ a: 'Jeff Bezos', q: 'A brand for a company is like a reputation for a person. You earn reputation by trying to do hard things well.' },
		{ a: 'Socrates', q: 'The way to gain a good reputation is to endeavor to be what you desire to appear.' }
	];

	setInterval(function () {
		$('.quote, .author').fadeOut(500, function () {
			if($(this).hasClass('author')) return;
			var quote;
			while(!quote || quote.q === $('.quote').html()) {
				quote = quotes[Math.floor(Math.random()*quotes.length)];
			}
			$('.quote').html(quote.q);
			$('.author').html(quote.a);
			$('.quote, .author').fadeIn(500);
		});
	}, 5000);

});