'use strict';

$(function () {


	var inputPlaceholder = 'leave your email';
	$('#email, #email-small').focus(function () {
		if($(this).val() == inputPlaceholder) {
			$(this).val('');
		}
	});

	$('#email, #email-small').blur(function () {
		if($(this).val() == '') {
			$(this).val(inputPlaceholder);
		}
	});

	Common.centerHorizontally('.join, .thank-you');
	TweenLite.set('.thank-you', {autoAlpha: 0});

	$('#submit').click(function () {
		var re = /[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i;
		if(!re.test($('#email').val())) {
			$('#email').addClass('error');
			return false;
		}

		TweenLite.to('.join', 1, {autoAlpha: 0});
		TweenLite.to('.thank-you', 1, {autoAlpha: 1});

		var timeline = new TimelineMax({paused: true});
		timeline.from('.thank-you img', 1, {autoAlpha: 0, marginTop: 1000, rotation: "-3.14rad"});
		timeline.to('.thank-you img', 0.2, { scale: 2})
		timeline.to('.thank-you img', 0.2, { scale: 1})

		timeline.play();
		dataLayer.push({'event' : 'formSubmitted'});
		return true;
	});
	// $('.mc-form').ajaxChimp({
		// https://network.us11.list-manage.com/subscribe/post-json?u=a41ed8b957142c73c1aa5ab2e&id=3e8ec31908&c=jQuery111204817536131013185_1456825348742&EMAIL=test%40o2.pl&_=1456825348743
		// url: 'https://network.us11.list-manage.com/subscribe/post-json?u=a41ed8b957142c73c1aa5ab2e&id=3e8ec31908',
	// });
});
