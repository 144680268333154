'use strict';

(function () {
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    var lastTime = 0;

    for (var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        var vendor = vendors[x];
        window.requestAnimationFrame = window[vendor + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendor + 'CancelAnimationFrame'] || window[vendor + 'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame) {
        window.requestAnimationFrame = function (callback) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function () {
                return callback(currTime + timeToCall);
            }, timeToCall);

            lastTime = currTime + timeToCall;
            return id;
        };
    }

    if (!window.cancelAnimationFrame) window.cancelAnimationFrame = function (id) {
        return clearTimeout(id);
    };
})();

$(document).ready(onReady);

var THICKNESS = Math.pow( 80, 2 ),
	RATIO = 140 / 90,
	COLS = 140,
	ROWS = Math.floor(COLS / RATIO),
	EASE = 1,
	DRAG_PULL = 0.5,
	DRAG_PUSH = 0.9,
	width,
	height,
	renderer,
	container,
	mx, my,
	cellX,
	cellY,
	circlesNum = COLS * ROWS,
	circles = [],
	direction = false,
	d,dx,dy,f;

function onReady() {

	width = $('#map').width();
	height = $('#map').height();

	cellX = width / COLS;
	cellY = height / ROWS;

	renderer = PIXI.autoDetectRenderer(width, height, {antialias: true, backgroundColor: 0xffffff}, false);
	container = new PIXI.Container(0xffffff);


	$('#map_filter').on('load', function () {
		var img = $('#map_filter')[0];
	 	var canvas = $('<canvas/>')[0];
	 	canvas.width = img.naturalWidth;
	 	canvas.height = img.naturalHeight;

	 	canvas.getContext('2d').drawImage(img, 0, 0, img.naturalWidth, img.naturalHeight);
	 	var pixelData = canvas.getContext('2d').getImageData(0, 0, img.naturalWidth, img.naturalHeight).data;
		var circleTexture = new PIXI.Texture.fromImage("/img/circle.png", false, PIXI.SCALE_MODES.NEAREST);
		
		for(var i = 0; i < circlesNum; i++) {

			var x = cellX * (i % COLS);
			var y = cellY * Math.floor(i / COLS);
			
			if(pixelData[i * 4] == 255) continue;

			var circle = new PIXI.Sprite(circleTexture);
			circle.x = circle.ox = x;
			circle.y = circle.oy = y;
			circle.vx = circle.vy = 0;
			circles.push(circle);
			container.addChild(circle);
		}

		$('#map').append(renderer.view);
	}).each(function () {if(this.complete) $(this).load()});

	// height / width = 900 / 1400
	var cursor = $('#map-cursor');
	$('#map,#map-cursor').mousemove(function (e) {
		var bounds = $('#map')[0].getBoundingClientRect();

		mx = 1400 * (e.clientX - bounds.left) / width;
		my = 900 * (e.clientY - bounds.top) / (900 * width / 1400);
	});

	$('#map-toggle').change(function () {
		direction = !this.checked;
	});

	$('#map').click(function () {
		$('#map-switch').trigger('click');
	});

	TweenLite.set($('#map-switch .circle .plus'), {autoAlpha: 0});
	$('#map-switch').click(function () {
		var checked = $('#map-toggle').prop('checked');
		$('#map-toggle').prop('checked', !checked).trigger('change');
		$(this).toggleClass('plus');
		$('#map-container').toggleClass('plus');

		if(checked) {
			TweenLite.to($('#map-switch .circle .plus'), 0.3, {autoAlpha: 1});
			TweenLite.to($('#map-switch .circle .minus'), 0.3, {autoAlpha: 0});
			TweenLite.to($('#map-switch .circle'), 0.3, {left: 48});
		} else {
			TweenLite.to($('#map-switch .circle .plus'), 0.3, {autoAlpha: 0});
			TweenLite.to($('#map-switch .circle .minus'), 0.3, {autoAlpha: 1});
			TweenLite.to($('#map-switch .circle'), 0.3, {left: 0});

		}
	})

	var resize = function () {
		width = Math.min($(window).width(), 1400);
		
		renderer.view.style.width = width + 'px';
		
	}

	$(window).resize(resize);
	resize();

	requestAnimationFrame(render);
}

var px=0, py=0,fx=0,fy=0, md = 100, force;

function render() {

	for(var i = 0; i < circles.length; i++) {
		var circle = circles[i];

		if(typeof mx == 'undefined' || typeof my == 'undefined' ) {
			break;
		}

		d = Math.sqrt((dx = mx - circle.x) * dx + (dy = my - circle.y) * dy);

		if(direction) {
			circle.vx = (circle.ox - circle.x) * .5;
			circle.vy = (circle.oy - circle.y) * .5;

			if(d < md) {
				force = (Math.abs(d - md) / md) * 0.5;

				circle.vx += dx * force;
				circle.vy += dy * force;
			}

			circle.x += circle.vx;
			circle.y += circle.vy;


		} else {
			px = circle.x - (dx / d) * 800 / d;
			py = circle.y - (dy / d) * 800 / d;

			// if(d >= md) {
			// 	px = circle.x;
			// 	py = circle.y;
			// }

			fx = (fx + (circle.ox - circle.x)) / 3;
			fy = (fy + (circle.oy - circle.y)) / 3;


			circle.x = px + fx;
			circle.y = py + fy;
		}

	}

	renderer.render(container);
	requestAnimationFrame(render);
}

// Tool -- comment out in production

// $(function () {
// 	var img = $('#map_filter')[0];
// 	var canvas = $('<canvas/>')[0];
// 	canvas.width = img.width;
// 	canvas.height = img.height;
// 	canvas.getContext('2d').drawImage(img, 0, 0, img.width, img.height);

// 	width = $('#map').width();
// 	height = $('#map').height();

// 	cellX = width / COLS;
// 	cellY = height / ROWS;
// 	var string = '';
// 	for(var i = 0; i < circlesNum; i++) {

// 		var x = cellX * (i % COLS);
// 		var y = cellY * Math.floor(i / COLS);

// 		var pixelData = canvas.getContext('2d').getImageData((x*img.width)/width, (y*img.height)/height, 1, 1).data;

// 		if(pixelData[0] == 255) continue;

// 		string += '' + i + ',';
// 	}

// 	console.log(string)


// });
